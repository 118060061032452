declare global {
  interface NavigatorPlugins {
    javaEnabled: () => boolean;
  }
}

export const verifyBrowser = (): boolean => typeof window !== "undefined";

export const collectBrowserInfo = () => {
  const toString = (val?: string | number | boolean) => {
    return typeof val === "undefined" ? "" : `${val}`;
  };
  const colorDepth = toString(window.screen.colorDepth);
  const screenHeight = toString(window.screen.height);
  const screenWidth = toString(window.screen.width);
  const userAgent = window.navigator.userAgent;
  const language = window.navigator.language || "en";
  const d = new Date();
  const timeZoneOffset = toString(d.getTimezoneOffset());
  const javaEnabled = toString(window.navigator.javaEnabled?.());

  return {
    acceptHeader: "*/*",
    colorDepth,
    language,
    javaEnabled,
    screenHeight,
    screenWidth,
    userAgent,
    timeZoneOffset,
  };
};
